<template>
  <div class="h-screen flex w-full bg-img" id="page-login">
    <!-- <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 mx-auto self-center"> -->
    <div
      class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center"
    >
      <img
        src="@/assets/images/pages/graphic-3.png"
        alt="graphic-404"
        class="mx-auto mb-4"
      />
      <h1 class="mb-12 text-5xl d-theme-heading-color">
        Start the work for the day
      </h1>
      <vs-button size="large" @click="updateUserWorkingStatus()"
        >Start</vs-button
      >
    </div>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import constants from "../../constants.json";
// import EventBus from "../components/eventbus";

export default {
  mounted() {
    console.log("start work");
  },
  methods: {
    updateUserWorkingStatus() {
      let url = `${constants.SERVER_API}updateWorkingStatus`;
      let params = {
        status: "started",
      };
      console.log(params);
      axios
        .post(url, params, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          let role = localStorage.getItem("role");
          let team = localStorage.getItem('team')
          // let sub_team = localStorage.getItem('sub_team')
          if (role === "cm1") {
            router.push("/");
          } else if (role === "cm4" && team === 'Admin' || team === 'ExEdGM') {
            router.push("/adminDashboard");
          } else if (role === "cm4" && team == 'SR') {
            router.push("/workDashboard");
          } else if (role === "cm4" && team == 'GM') {
            router.push("/GMWorkDashboard");
          } else if (role === "cm4" && team == 'Accounts') {
            router.push("/enrollments");
          }else if (role === "cm2") {
            router.push("/cm2Form");
          } else if (role === "cm3") {
            router.push("/receipts");
          } else if (role === "wc") {
            router.push("/warehouse");
          } else if (role === "wa") {
            router.push("/Warehouseaccount");
          } else if (role === "ac1") {
            router.push("/acadsDashboard");
          } else if (role === "fi1") {
            router.push("/tallyReceipts");
          } else if (role === "ac2") {
            router.push("/acadsAdminDashboard");
          } else if (response.data === "ac1") {
            router.push("/acadsDashboard");
          } else if (role === "fe") {
            router.push("/MrReco");
          } else if (role === "cmca") {
            router.push("/CallLogs");
          } else if (role === "baca") {
            router.push("/IIMLManagementcalllogs");
          } else if (role === "hr") {
            router.push("/attendanceEdit");
          } else if (role === "exed" || role === "exed2") {
            router.push("/iimlDashboard");
          } else if (role === "wh1" || role === "wh2") {
             router.push("/DispatchDetails");
          } else {
            router.push("/entryRolePage");

            // this.$vs.notify({
            //   title: "You are Unauthorized",
            //   text: "Try logging in with different credentials",
            //   color: "danger",
            //   position: "top-center",
            // });
            // router.push("/pages/login");
          }
          //   this.handleNotification(response);
          //   if (role.status === "success") {
          //     this.open = false;
          //     location.reload();
          //   }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style></style>
